import React, { FC, useState } from "react"
import { graphql, PageProps } from "gatsby"
// import { WindowLocation } from "reach-router"
import Img from 'gatsby-image';

// @ts-ignore
import BlockContent from '@sanity/block-content-to-react'


import SEO from "../components/seo"
import { Raid, RaidLevel } from "../models/raid"
import { Block } from "../models/event"
import Box from "../components/box"
import Link from "../components/link"
import RaidIcon from "../components/raid/raid-icon"
import Sparkle from "../images/icons/sparkle";
import Heading from "../components/heading";
import Text from "../components/text";
import { PokemonType } from "../helpers/types";
import TypeTag from "../components/pokemon/type-tag";
import ArrowBoost from "../images/icons/arrow-boost";
import MoveTag from "../components/pokemon/move-tag";
import Button from "../components/button";
import SearchStringBuilder from "../components/search-string-builder";
import { useAllPokemon } from "../hooks/useAllPokemon";
import { RaidBattle } from "../lib/raid";
import { isBoostedForWeather, Weather } from "../models/weather";
import WeatherSelector from "../components/weather/weather-selector";
import WeatherIcon from "../components/weather/weather-icon";
import Wrapper from "../containers/wrapper";
import { getPokemonForm } from "../models/pokemon";
import StatBar from "../components/dex/stat-bar";

interface RaidPage extends Raid {
  summary: string;
  _rawBody: {
    children: Block[];
  }
}

interface Props extends PageProps {
  data: {
    sanityRaid: RaidPage
  }
}

const RaidTemplate: FC<Props> = ({ data, location: { state: { weather } } }) => {
  const [selectedWeather, setSelectedWeather] = useState<Weather>(weather)
  const { sanityRaid: raid } = data;
  console.log(raid)
  const { name, pokemon: pokemonRef, tier, slug } = raid

  const allPokemon = useAllPokemon()
  const { pokemonId, shiny, form, megaForm } = pokemonRef
  const { node: pokemon } = allPokemon[pokemonId - 1]
  const raidBattle = new RaidBattle(pokemon, tier, form, megaForm)

  const formInfo = getPokemonForm(pokemonRef, pokemon)
  const image = formInfo.image ? formInfo.image.fluid : null

  const isBoosted = () => isBoostedForWeather(raidBattle.types as PokemonType[], selectedWeather)

  return (
    <>
      <SEO
        title={`${raid.name} Raid Counters Pokemon Go`}
        description={`Pokemon Go ${raid.name} raid counters. Find out the best Pokemon to use against ${raid.name} raid boss.`} />

      <Wrapper>
        <WeatherSelector value={selectedWeather} onChange={selection => setSelectedWeather(selection)} mb={6} />
      </Wrapper>

      {/* Header */}
      <Box display="flex" alignItems="flex-start">
        <Box width="150px" position="relative">
          {image && <Img fluid={image} />}
          {shiny && <Box
            position="absolute"
            top="0"
            right="0"
          >
            <Sparkle fill="black" height="15px" />
          </Box>}
        </Box>
        <Box ml={6} flex="1" pr={3}>
          <Heading as="h2" fontSize={7} m={0}>
            {raidBattle.name}
          </Heading>
          <Text fontSize={4} mt={0} mb={2} color="grey.4">CP {raidBattle.cp}</Text>
          <Box mr={4} display="grid" gridTemplateColumns=".75fr 2fr" gridColumnGap={2} alignItems="center">
              <Text fontSize={2} m={0}>Attack</Text>
              <StatBar max={414} value={formInfo.base_attack}/>
              <Text fontSize={2} m={0}>Defense</Text>
              <StatBar max={396} value={formInfo.base_defense}/>
              <Text fontSize={2} m={0}>Stamina</Text>
              <StatBar max={496} value={formInfo.base_stamina}/>
          </Box>
        </Box>
      </Box>

      {/* Quick info */}
      <Box display="flex" mt={8} px={4} py={4} bg="grey.1" borderTop="1px solid" borderBottom="1px solid" borderColor="grey.2">
        {/* Catch info */}
        <Box flex="1">
          {/* <Heading as="h3" fontSize={3} m={0} fontWeight="bold">Catch Info</Heading> */}
          <Text fontSize={2} mb={0}>LVL 20 CP: <strong>{raidBattle.perfectCpLevels.normal}</strong></Text>
          <Box display="flex" alignItems="flex-start">
            <Text fontSize={2} mb={0} bg={isBoosted() ? 'primary' : 'transparent'}>LVL 25 CP: <strong>{raidBattle.perfectCpLevels.boosted}</strong></Text>
            {raidBattle.boostedWeather
              .map(weather =>
                <Box key={weather} display="flex" bg='blackAlways' ml={1} borderRadius="large"><WeatherIcon height="17px" weather={weather} /></Box>
              )
            }
          </Box>
        </Box>
        {/* Types */}
        <Box flex="1" display="flex" alignItems="center" justifyContent="center">
          <Box display="flex" justifyContent="center" alignItems="center">
            {raidBattle.types.map(type => type as PokemonType).map(type => (
              <TypeTag
                key={`raid-boss-${name}-${type}`}
                type={type}
                onlyShowIcon
                mt={0}
                mr={2} />
            ))}
            {isBoosted() && <ArrowBoost fill="primary" height="16px" />}
          </Box>
        </Box>
        {/* Raid Tier */}
        <Box flex="1" display="flex" justifyContent="center" alignItems="center">
          <RaidIcon tier={tier} iconWidth="14px" iconSpacing={1} iconFill="grey.5" justifyContent="center" />
        </Box>
      </Box>


      <Wrapper>
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={8} mb={3}>
          <Heading as="h3" fontSize={3}>Weaknesses</Heading>
          <Link fontSize={2} to={`/type-effectiveness?types=${raidBattle.types.join(',')}`} textAlign="center">View all</Link>
        </Box>

        <Box display="flex" flexWrap="wrap">
          {raidBattle.weaknesses && Object.entries(raidBattle.weaknesses).sort(([_, a], [__, b]) => b - a).map(([type, multiplier]) => (
            <Box display="flex" alignItems="center">
              <TypeTag
                key={`raid-boss-${name}-weaknesses-${type}`}
                type={type as PokemonType}
                multiplier={multiplier}
                mt={2}
                mr={2}
                isBoosted={isBoostedForWeather([type as PokemonType], selectedWeather)}
              />
            </Box>
          ))}
        </Box>

        <Heading as="h3" fontSize={3} mt={6} mb={2}>Moveset</Heading>
        <Heading as="h4" fontSize={3} mb={2} fontWeight="normal">Fast Attacks</Heading>
        <Box display="flex" flexWrap="wrap">
          {raidBattle.fastMoves.map((move, index) => (
            <MoveTag key={`move-${move.id}`} move={move} />
          ))}
        </Box>
        <Heading as="h4" fontSize={3} mt={3} mb={2} fontWeight="normal">Charged Attacks</Heading>
        <Box display="flex" flexWrap="wrap">
          {raidBattle.chargedMoves.map((move, index) => (
            <MoveTag key={`move-${move.id}`} move={move} />
          ))}
        </Box>
        <Button variant="secondary" mt={4} onClick={() => raidBattle.getBattleReccomendation(allPokemon)}>View Counters</Button>
        <SearchStringBuilder pokemon={[]} moveTypes={raidBattle.weaknesses ? Object.entries(raidBattle.weaknesses).map(([type]) => type as PokemonType) : []} />
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    sanityRaid(id: {eq: $id}) {
      ...RaidFields
      summary
      _rawBody
    }
  }
`

export default RaidTemplate
